<template>
  <div class="d-flex flex-column flex-root">
    <div id="kt_login" class="login login-signin-on bg-color">
      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative">
        <div class="view-wrap flex-column-fluid">
          <router-view />
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReferralLayout",
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>

<style lang="scss">
@import "../../assets/sass/pages/login/login-1";
.bg-color {
  background: #fefefe;
}
</style>
