<template>
  <div class="header">
    <img class="header-logo" :src="logoPath" alt />
    <ReferralForm :id="referralFormId" border-color="black" />
  </div>
</template>

<script>
import ReferralForm from "../form/index";

export default {
  name: "Header",

  components: {
    ReferralForm,
  },

  data() {
    return {
      referralFormId: "0",
    };
  },

  computed: {
    logoPath() {
      return process.env.BASE_URL + "media/landing/logos/logo-red.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  max-width: 1280px;
  width: 100%;
  padding: 20px 60px 60px;
  margin: 0 auto;
  background-image: url("../../../../public/media/landing/bg/bg-1.png"),
    url("../../../../public/media/landing/bg/bg-2.png");
  background-repeat: no-repeat;
  background-position: left bottom, right bottom;
  background-size: 403px 207px, contain;
  background-color: $white;

  .header-logo {
    width: 116px;
    height: 27px;
    margin-bottom: 68px;
  }
}

@media (max-width: 965px) {
  .header {
    background-image: url("../../../../public/media/landing/bg/bg-1.png");
    background-position: left bottom;
    background-size: 403px 207px;
    padding: 20px 100px 60px;
  }
}

@media (max-width: 850px) {
  .header {
    padding: 20px 50px 30px;

    .header-logo {
      width: 134px;
      height: 31px;
    }
  }
}

@media (max-width: 570px) {
  .header {
    padding: 20px 20px 30px;
  }
}
</style>
