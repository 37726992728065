<template>
  <div class="about">
    <div class="header-block">
      <h2 class="title">
        {{ $t("page.referralForm.aboutTitle") }}
      </h2>

      <div class="main-info-block">
        <AboutInfoBlock
          v-for="(constituent, index) in constituents"
          :key="index"
          :constituent="constituent"
          class="about-info-block"
        />
      </div>
    </div>

    <ComparisonStations class="comparison-stations" />
    <ComparisonStationsTable class="comparison-stations-table" />
  </div>
</template>

<script>
import AboutInfoBlock from "./components/AboutInfoBlock";
import ComparisonStations from "./components/ComparisonStations";
import ComparisonStationsTable from "./components/ComparisonStationsTable";

export default {
  name: "About",

  components: {
    AboutInfoBlock,
    ComparisonStations,
    ComparisonStationsTable,
  },

  computed: {
    constituents() {
      return [
        {
          title: this.$t("page.referralForm.pump.title"),
          description: this.$t("page.referralForm.pump.description"),
          icon: process.env.BASE_URL + "media/landing/svg/constituent-1.svg",
        },
        {
          title: this.$t("page.referralForm.boiler.title"),
          description: this.$t("page.referralForm.boiler.description"),
          icon: process.env.BASE_URL + "media/landing/svg/constituent-2.svg",
        },
        {
          title: this.$t("page.referralForm.pumpGroup.title"),
          description: this.$t("page.referralForm.pumpGroup.description"),
          icon: process.env.BASE_URL + "media/landing/svg/constituent-3.svg",
        },
        {
          title: this.$t("page.referralForm.conditioning.title"),
          description: this.$t("page.referralForm.conditioning.description"),
          icon: process.env.BASE_URL + "media/landing/svg/constituent-4.svg",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  max-width: 1280px;
  width: 100%;
  padding: 100px 60px 40px;
  margin: 0 auto;
  background-image: url("../../../../../public/media/landing/bg/bg-4.png");
  background-repeat: no-repeat;
  background-position: left 360px;
  background-size: 320px 450px;
  background-color: $white;
  font-family: "Montserrat";
  font-style: normal;
  color: #1a1a1b;

  .header-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
      font-weight: 700;
      font-size: 44px;
      line-height: 58px;
      max-width: 450px;
      margin-right: 80px;
    }

    .main-info-block {
      display: flex;
      flex-direction: column;

      .about-info-block {
        margin-bottom: 30px;
      }
    }
  }

  .comparison-stations {
    margin-bottom: 80px;
  }
}

@media (max-width: 1260px) {
  .about {
    background-image: url("../../../../../public/media/landing/bg/bg-5.png");
    background-position: right 70px;
    background-size: 250px 410px;

    .header-block {
      .title {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
      }

      .main-info-block {
        display: flex;
        flex-direction: column;

        .about-info-block {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .about {
    background-position: right 60px;
    padding-top: 80px;

    .header-block {
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 58px;
      }

      .main-info-block {
        display: flex;
        flex-direction: column;

        .about-info-block {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .about {
    background-position: right 40px;
    padding: 80px 20px 40px;
  }
}
</style>
