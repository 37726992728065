<template>
  <div data-cy="referral-page" class="referral-page">
    <Header />
    <Benefits />
    <About />
    <Services />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Benefits from "./components/Benefits/index";
import About from "./components/About/index";
import Services from "./components/Services/index";
import Footer from "./components/Footer";

export default {
  name: "Referral",

  components: {
    Header,
    Benefits,
    About,
    Services,
    Footer,
  },
};
</script>
