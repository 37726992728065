<template>
  <div :class="{'input-file form-control': true, error: error}">
    <div :class="{'form-group__label label-gray': true, 'error-label': error}">{{ label }}</div>
    <label :for="_uid">
      <input :id="_uid" type="file" class="input-file-hidden" @change="change" :accept="accept">
      <div class="input-file-value">{{ filename }}</div>
      <SvgIcon
        icon-folder="Design"
        icon-name="Attach"
      />
    </label>
    <div class="input-file-error error-message" v-if="error">
      {{ $t("form.allowedExt") }}: {{ allowedExt.join(", ") }}
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/svg/SvgIcon";

export default {
  name: "InputFile",

  components: {
    SvgIcon,
  },

  props: {
    label: {
      type: String,
      default: "",
    },

    allowedExt: {
      type: Array,
      default: () => [],
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filename: "",
      file: null,
    }
  },

  computed: {
    accept() {
      return this.allowedExt.length === 0 ? "*" : this.allowedExt.map((el) => `.${el}`).join(",") 
    }
  },

  methods: {
    async change(e) {
      const file = e.target;
      
      this.filename = file.files.length > 0 ? file.files[0].name : "";

      this.$emit("change", file.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0.7rem 0 2.5rem 0;
  position: relative;
}

.form-control {
  border-radius: 0.25rem;
  font-weight: 700;
  position: relative;

  &:focus {
    border-color: $primary !important;
  }

  &:focus + label {
    color: $primary;
  }

  &:disabled {
    border: none;
  }
}

.form-control-md {
  padding-left: 1.4rem;
}

.form-group__label {
  font-size: 1.07rem;
  margin-left: 5px;
  padding: 0 5px;
  position: absolute;
  left: 10px;
  top: -15px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
  font-weight: 400;

  .tooltip-icon {
    margin-bottom: 0.1rem;
  }
}
.form-group__label.label-gray {
  color: $gray-500;
}

.form-group__label.label-black {
  color: $black;
}

.form-group__label.required {
  &:after {
    content: "*";
    position: absolute;
    color: $danger;
    font-weight: bold;
    right: -5px;
  }
}

.error-message {
  position: absolute;
  font-size: 0.9rem;
  color: $danger;
  bottom: -9px;
  left: 10px;
  margin-left: 5px;
  padding: 0 5px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
  white-space: nowrap;
}

.app-input.border-black {
  border-color: $black;
}

.form-control.error,
.app-input.error {
  border-color: $danger;
}

.error-label {
  color: $danger !important;
}

.input-file {
  font-weight: 400;
  padding: 0;

  &-hidden {
    display: none;
  }

  &-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 100 0 0;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0.5rem 1.5rem;

    .svg-icon {
      padding: 0;
      margin-right: -12px;
      fill: #404253;
    }
  }
}
</style>
