<template>
  <div class="service-info-block">
    <h6 class="title">{{ service.title }}</h6>
    <p class="description">{{ service.description }}</p>
  </div>
</template>

<script>
export default {
  name: "ServiceInfoBlock",

  props: {
    service: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.service-info-block {
  width: 319px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  font-family: "Montserrat";
  color: #1a1a1b;

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 1200px) {
  .service-info-block {
    width: 100%;
  }
}
</style>
