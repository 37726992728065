<template>
  <div class="referral-form-wrapper">
    <div v-if="!isFooter" class="mb-11 pt-lg-0">
      <h3 class="title">
        {{ $t("page.referralForm.title") }}
      </h3>
    </div>

    <b-form
      :data-cy="`ref-form-${id}`"
      class="referral-form"
      novalidate
      @submit.stop.prevent="onSubmitForm"
    >
      <div class="tabs-nav">
        <div
          v-for="(item, i) in tabs.items"
          :key="i"
          :class="{'tabs-nav-item': true, 'active': tabs.showIndex === i}"
          @click="setTab(i)"
        >{{ item.title }}</div>
      </div>

      <div class="tabs">
        <div
          v-for="(item, i) in tabs.items"
          :key="i"
          class="tabs-content"
        >
          <template v-if="tabs.showIndex === i">

            <div v-if="i === 0" class="tabs-content-item">
              <p class="subtitle">
                {{ $t("page.referralForm.descriptionDefault") }}
              </p>
              <InputElement
                v-model="form.firstName"
                :data-cy="`first-name-input-${id}`"
                class="component-input"
                size="lg"
                :border-color="borderColor"
                :with-error="errors.firstName"
                :error-message="$t('validation.required')"
                :label="$t('label.firstName')"
                @blur="removeErrorClass('firstName')"
                @input="removeErrorClass('firstName')"
              />
              <CleaveElement
                :value="phoneWithPlus"
                :options="cleaveOptions"
                :data-cy="`phone-input-${id}`"
                class="component-input"
                size="lg"
                :border-color="borderColor"
                :label="$t('label.phone')"
                :with-error="errors.phone"
                :error-message="phoneErrorMsg"
                @input="onChangePhone"
                @blur="removeErrorClass('phone')"
              />

              <MultiselectElement
                :value="checkValueExists(form.region)"
                :data-cy="`region-select-${id}`"
                class="component-input-select"
                size="lg"
                :options="regions"
                item-label="name"
                :with-error="errors.region"
                :error-message="$t('validation.required')"
                :label="$t('label.region')"
                :border-color="borderColor"
                @select="onSelectRegion"
                @open="removeErrorClass('region')"
              />

              <TextareaElement
                v-model="form.comment"
                :data-cy="`comment-input-${id}`"
                class="component-input-textarea"
                :label="$t('label.comment')"
                :border-color="borderColor"
                @blur="removeErrorClass('comment')"
                @input="removeErrorClass('comment')"
              />

              <div class="button-wrapper" :data-cy="`send-btn-${id}`" type="submit">
                <b-button
                  type="submit"
                  variant="danger"
                  class="button auth-btn submit-btn font-weight-bolder font-size-h6 py-4"
                >
                  <div class="button-text">
                    {{ $t("button.send") }}
                  </div>
                </b-button>
              </div>
            </div>

            <div v-if="i === 1" class="tabs-content-item">
              <b-row>
                <b-col cols="12">
                  <InputElement
                    v-model="form.building_area"
                    class="component-input"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="building_area-input"
                    :label="$t('referral.form.building_area')"
                    inputType="number"
                    :required="true"
                    :with-error="errors.building_area"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('building_area')"
                    @input="removeErrorClass('building_area')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <MultiselectElement
                    :value="checkValueExists(form.roof_type)"
                    :options="referralOptionsRoofTypes"
                    :label="$t('referral.form.roof_type')"
                    class="component-input-select"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="roof_type-select"
                    track-by="id"
                    @select="onSelectRoofType"
                    :required="true"
                    :with-error="errors.roof_type"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('roof_type')"
                    @input="removeErrorClass('roof_type')"
                  />
                </b-col>
                <b-col md="6" sm="12">
                  <MultiselectElement
                    :value="checkValueExists(form.type_of_roof_covering)"
                    :options="referralOptionsTypesOfRoofCovering"
                    :label="$t('referral.form.type_of_roof_covering')"
                    class="component-input-select"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="type_of_roof_covering-select"
                    track-by="id"
                    @select="onSelectTypeOfRoofCovering"
                    :required="true"
                    :with-error="errors.type_of_roof_covering"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('type_of_roof_covering')"
                    @input="removeErrorClass('type_of_roof_covering')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <InputElement
                    v-model="form.consumption_per_month_in_summer"
                    data-cy="consumption_per_month_in_summer-input"
                    :label="$t('referral.form.consumption_per_month_in_summer')"
                    class="component-input"
                    size="lg"
                    :border-color="borderColor"
                    inputType="number"
                    :required="true"
                    :with-error="errors.consumption_per_month_in_summer"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('consumption_per_month_in_summer')"
                    @input="removeErrorClass('consumption_per_month_in_summer')"
                  />
                </b-col>
                <b-col md="6" sm="12">
                  <InputElement
                    v-model="form.consumption_per_month_in_winter"
                    data-cy="consumption_per_month_in_winter-input"
                    :label="$t('referral.form.consumption_per_month_in_winter')"
                    class="component-input"
                    size="lg"
                    :border-color="borderColor"
                    inputType="number"
                    :required="true"
                    :with-error="errors.consumption_per_month_in_winter"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('consumption_per_month_in_winter')"
                    @input="removeErrorClass('consumption_per_month_in_winter')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" sm="12">
                  <MultiselectElement
                    :value="checkValueExists(form.presence_of_battery)"
                    :options="referralOptionsPresenceOfBattery"
                    :label="$t('referral.form.presence_of_battery')"
                    class="component-input-select"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="presence_of_battery-select"
                    track-by="code"
                    @select="onSelectPresenceOfBattery"
                    :required="true"
                    :with-error="errors.presence_of_battery"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('presence_of_battery')"
                    @input="removeErrorClass('presence_of_battery')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <InputElement
                    v-model="form.number_of_phases_under_contract"
                    data-cy="number_of_phases_under_contract-input"
                    :label="$t('referral.form.number_of_phases_under_contract')"
                    class="component-input"
                    size="lg"
                    :border-color="borderColor"
                    inputType="number"
                    :required="true"
                    :with-error="errors.number_of_phases_under_contract"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('number_of_phases_under_contract')"
                    @input="removeErrorClass('number_of_phases_under_contract')"
                  />
                </b-col>
                <b-col md="6" sm="12">
                  <InputElement
                    v-model="form.number_of_phases_actual"
                    data-cy="number_of_phases_actual-input"
                    :label="$t('referral.form.number_of_phases_actual')"
                    class="component-input"
                    size="lg"
                    :border-color="borderColor"
                    inputType="number"
                    :required="true"
                    :with-error="errors.number_of_phases_actual"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('number_of_phases_actual')"
                    @input="removeErrorClass('number_of_phases_actual')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <MultiselectElement
                    :value="checkValueExists(form.connection_type)"
                    :options="referralOptionsConnectionTypes"
                    :label="$t('referral.form.connection_type')"
                    class="component-input-select"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="connection_type-select"
                    track-by="id"
                    @select="onSelectConnectionType"
                    :required="true"
                    :with-error="errors.connection_type"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('connection_type')"
                    @input="removeErrorClass('connection_type')"
                  />
                </b-col>
                <b-col md="6" sm="12">
                  <MultiselectElement
                    :value="checkValueExists(form.type_of_solar_panel_placement)"
                    :options="referralOptionsTypesOfSolarPanelPlacement"
                    :label="$t('referral.form.type_of_solar_panel_placement')"
                    class="component-input-select"
                    size="lg"
                    :border-color="borderColor"
                    data-cy="type_of_solar_panel_placement-select"
                    track-by="id"
                    @select="onSelectTypeOfSolarPanelPlacement"
                    :required="true"
                    :with-error="errors.type_of_solar_panel_placement"
                    :error-message="$t('validation.required')"
                    @blur="removeErrorClass('type_of_solar_panel_placement')"
                    @input="removeErrorClass('type_of_solar_panel_placement')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <InputFile
                    :label="$t('form.attachBrief')"
                    :error="errors.brief"
                    :allowed-ext="allowedBriefExt"
                    @change="onChangeBrief"
                  />
                </b-col>
                <b-col md="6" sm="12">
                  <div class="button-wrapper brief-download-btn-wrapper">
                    <b-button
                      v-if="briefTemplate?.url"
                      type="button"
                      variant="danger"
                      class="button auth-btn submit-btn font-weight-bolder font-size-h6 py-4 brief-download-btn"
                      @click="dowloadBriefTemplate"
                    >
                      <div class="button-text">
                        {{ $t("button.downloadBriefTpl") }}
                      </div>
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <div class="button-wrapper" :data-cy="`send-btn-${id}`" type="submit">
                <b-button
                  type="submit"
                  variant="danger"
                  class="button auth-btn submit-btn font-weight-bolder font-size-h6 py-4"
                >
                  <div class="button-text">
                    {{ $t("button.send") }}
                  </div>
                </b-button>
              </div>
            </div>

          </template>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import storage from "@/services/storage.service";
import notify from "@/services/notify.service";
import notifyDuration from "@/configs/notifyDuration.json";
import apolloMixin from "@/mixins/apollo.mixin";
import { regionsQuery, requiredData, documentFormData } from "./gql/queries";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";
import InputFile from "@/components/form/InputFile";
import axios from 'axios';

export default {
  name: "ReferralForm",
  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
    CleaveElement,
    InputFile,
  },

  mixins: [apolloMixin],

  props: {
    isFooter: {
      type: Boolean,
      default: false,
    },

    borderColor: {
      type: String,
      default: "gray",
    },

    id: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    regions: [],
    referralOptions: {},
    tabs: {
      showIndex: 0,
      items: [
        {
          title: 'Особисті дані',
        },
        {
          title: 'Технічні дані',
        }
      ],
    },
    form: {
      agentHash: storage.getObj("agentHash") || null,
      firstName: "",
      phone: "38",
      region: null,
      comment: "",
      brief: null,
      building_area: "",
      roof_type: "",
      type_of_roof_covering: "",
      consumption_per_month_in_summer: "",
      consumption_per_month_in_winter: "",
      presence_of_battery: "",
      number_of_phases_under_contract: "",
      number_of_phases_actual: "",
      connection_type: "",
      type_of_solar_panel_placement: "",
    },
    errors: {
      firstName: false,
      phone: false,
      region: false,
      brief: false,
      building_area: false,
      roof_type: false,
      type_of_roof_covering: false,
      consumption_per_month_in_summer: false,
      consumption_per_month_in_winter: false,
      presence_of_battery: false,
      number_of_phases_under_contract: false,
      number_of_phases_actual: false,
      connection_type: false,
      type_of_solar_panel_placement: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    commentMaxLength: 255,
    minPowerKw: 10,
    maxPowerKw: 1000,
    briefTemplate: {},
    allowedBriefExt: ["pdf", "doc", "docx"],
  }),

  computed: {
    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    locale() {
      return this.$i18n.locale;
    },

    referralOptionsRoofTypes() {
      return this.referralOptions?.options?.roof_types.map((item) => {
        let newItem = JSON.parse(JSON.stringify(item));

        newItem.label = this.$t(`referral.form.select.options.roof_types.${newItem.label}`);

        return newItem;
      });
    },

    referralOptionsTypesOfRoofCovering() {
      return this.referralOptions?.options?.types_of_roof_covering.map((item) => {
        let newItem = JSON.parse(JSON.stringify(item));

        newItem.label = this.$t(`referral.form.select.options.types_of_roof_covering.${newItem.label}`);

        return newItem;
      })
    },

    referralOptionsConnectionTypes() {
      return this.referralOptions?.options?.connection_types.map((item) => {
        let newItem = JSON.parse(JSON.stringify(item));

        newItem.label = this.$t(`referral.form.select.options.connection_types.${newItem.label}`);

        return newItem;
      })
    },

    referralOptionsTypesOfSolarPanelPlacement() {
      return this.referralOptions?.options?.types_of_solar_panel_placement.map((item) => {
        let newItem = JSON.parse(JSON.stringify(item));

        newItem.label = this.$t(`referral.form.select.options.types_of_solar_panel_placement.${newItem.label}`);

        return newItem;
      })
    },

    referralOptionsPresenceOfBattery() {
      return [
        {id: 1, label: this.$t(`referral.form.select.options.presence_of_battery._1`)},
        {id: 0, label: this.$t(`referral.form.select.options.presence_of_battery._0`)},
      ];
    },
  },

  async created() {
    if (this.$route.query.agent) {
      this.form.agentHash = this.$route.query.agent;

      if (this.form.agentHash !== storage.getObj("agentHash")) {
        storage.setObj("agentHash", this.form.agentHash);
        await this.$router.push({ name: "ReferralForm" });
      }
    }

    await Promise.all([this.saveView({ agentHash: this.form.agentHash }), this.getRegions()]);
    await this.getRequiredData();
    await this.getBriefTeplate();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral", "saveView"]),

    async getRegions() {
      const { regions, referralOptions } = await this.$get(regionsQuery);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
      this.referralOptions = referralOptions;
    },

    async getRequiredData() {
      const { referralOptions } = await this.$get(requiredData);

      this.referralOptions = referralOptions;
    },

    setTab(i) {
      this.tabs.showIndex = i;
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    onSelectRoofType(val) {
      this.form.roof_type = val
    },

    onSelectTypeOfRoofCovering(val) {
      this.form.type_of_roof_covering = val
    },

    onSelectPresenceOfBattery(val) {
      this.form.presence_of_battery = val
    },
    
    onSelectConnectionType(val) {
      this.form.connection_type = val
    },

    onSelectTypeOfSolarPanelPlacement(val) {
      this.form.type_of_solar_panel_placement = val
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      this.errors.region = !this.form.region;

      this.errors.building_area = !this.form.building_area;
      this.errors.roof_type = !this.form.roof_type?.id;
      this.errors.type_of_roof_covering = !this.form.type_of_roof_covering?.id;
      this.errors.consumption_per_month_in_summer = !this.form.consumption_per_month_in_summer;
      this.errors.consumption_per_month_in_winter = !this.form.consumption_per_month_in_winter;
      this.errors.presence_of_battery = !this.form.presence_of_battery?.id;
      this.errors.number_of_phases_under_contract = !this.form.number_of_phases_under_contract;
      this.errors.number_of_phases_actual = !this.form.number_of_phases_actual;
      this.errors.connection_type = !this.form.connection_type?.id;
      this.errors.type_of_solar_panel_placement = !this.form.type_of_solar_panel_placement?.id;

      if(this.form.brief) {
        const briefExt = this.form.brief.name.split(".")[this.form.brief.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }

      return !Object.values(this.errors).some((error) => error);
    },

    async onSubmitForm() {
      if (!this.isValidForm()) return;

      const formData = {
        agentHash: this.form.agentHash,
        name: this.form.firstName,
        phone: this.form.phone,
        regionId: this.form.region.id,
        comment: this.form.comment,
        brief: this.form.brief,
        buildingArea: parseFloat(this.form.building_area),
        roofType: this.form.roof_type.id,
        typeOfRoofCovering: this.form.type_of_roof_covering.id,
        consumptionPerMonthInSummer: parseInt(this.form.consumption_per_month_in_summer),
        consumptionPerMonthInWinter: parseInt(this.form.consumption_per_month_in_winter),
        presenceOfBattery: this.form.presence_of_battery.id,
        numberOfPhasesUnderContract: parseInt(this.form.number_of_phases_under_contract),
        numberOfPhasesActual: parseInt(this.form.number_of_phases_actual),
        connectionType: this.form.connection_type.id,
        typeOfSolarPanelPlacement: this.form.type_of_solar_panel_placement.id,
      };

      const response = await this.createReferral(formData);

      notify.success(response.data.message, notifyDuration.permanent);

      this.form.firstName = "";
      this.form.phone = "38";
      this.form.region = null;
      this.form.comment = "";
      this.form.brief = null;
      this.form.building_area = "";
      this.form.roof_type = "";
      this.form.type_of_roof_covering = "";
      this.form.consumption_per_month_in_summer = "";
      this.form.consumption_per_month_in_winter = "";
      this.form.presence_of_battery = "";
      this.form.number_of_phases_under_contract = "";
      this.form.number_of_phases_actual = "";
      this.form.connection_type = "";
      this.form.type_of_solar_panel_placement = "";
    },

    async getBriefTeplate() {
      const response = await this.$get(documentFormData, { name: "brief" });

      this.briefTemplate = response.document;
    },

    dowloadBriefTemplate() {
      if(this.briefTemplate?.url) {

        axios.get(`storage/private/${this.briefTemplate.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");

          link.href = href;
          link.setAttribute("download", this.briefTemplate.filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },

    onChangeBrief(e) {
      if(typeof e !== 'undefined') {
        const briefExt = e.name.split(".")[e.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }
      
      this.form.brief = e
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-form-wrapper {
  font-family: "Montserrat", sans-serif;
  color: #1a1a1b;
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    max-width: 621px;
  }

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    max-width: 579px;
  }

  .referral-form {
    max-width: 491px;

    .component-input-textarea::v-deep {
      margin-bottom: 48px;

      .app-textarea {
        height: 93px;
      }
    }

    .button-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 96%;
      font-size: 12px;
      text-transform: uppercase;
      color: #ffffff;
      transform: skew(-20deg);
      margin-left: 9px;

      .button {
        width: 100%;
      }

      .button-text {
        transform: skew(20deg);
      }
    }
  }
}

.form-group::v-deep label {
  max-width: calc(100% - 28px);
  font-size: 14px !important;
  line-height: 1.2;
  top: auto !important;
  bottom: calc(100% - 15px);

  &::after {
    margin-left: 4px;
    right: initial !important;
  }
}

.input-file {
  border-color: #000000;
  color: #000000;
  height: 48px;
  margin: 0.7rem 0 2.5rem 0;
}

.input-file::v-deep .form-group__label {
  pointer-events: none;
  color: #000000;
  max-width: calc(100% - 28px);
  font-size: 14px !important;
  line-height: 1.2;
  top: auto !important;
  bottom: calc(100% - 7px);
}

.input-file::v-deep label {
  height: 48px;
}

.tabs {
  &-nav {
    display: flex;
    align-items: center;
    gap: 0 32px;
    margin-bottom: 24px;

    &-item {
      cursor: pointer;
      font-size: 20px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #868687;
      position: relative;
      font-weight: 500;
      transition: all .3s ease 0s;

      &:hover {
        color: #1A1A1B;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: transparent;
        transition: inherit;
      }

      &.active {
        color: #1A1A1B;

        &::after {
          background: #D11F1F;
        }
      }
    }
  }
}

.header {
  .tabs {
    &-content {
      &-item {
        min-height: 605px;

        @media screen and (max-width: 1024px) {
          min-height: initial;
        }
      }
    }
  }
}

.brief-download-btn {
  background: transparent;
  color: #F64E60;
  white-space: nowrap;
  font-size: 15px !important;
  height: 48px;

  &-wrapper {
    margin-left: 0;
    margin-top: .7rem;

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: 1090px) {
  .referral-form-wrapper {
    .subtitle {
      max-width: 500px;
    }
  }
}

@media (max-width: 1080px) {
  .referral-form-wrapper {
    .title {
      max-width: 550px;
    }
  }
}

@media (max-width: 965px) {
  .referral-form-wrapper {
    .title {
      max-width: 100%;
    }
  }
}

@media (max-width: 750px) {
  .referral-form-wrapper {
    .title {
      font-size: 32px;
    }
  }
}

@media (max-width: 650px) {
  .referral-form-wrapper {
    .title {
      padding-right: 60px;
    }
  }
}

@media (max-width: 650px) {
  .referral-form-wrapper {
    .title {
      padding-right: 40px;
    }
  }
}

@media (max-width: 580px) {
  .referral-form-wrapper {
    .title {
      padding-right: 20px;
    }
  }
}

@media (max-width: 570px) {
  .referral-form-wrapper {
    .title {
      padding-right: 40px;
    }
  }
}

@media (max-width: 440px) {
  .referral-form-wrapper {
    .title {
      padding-right: 0;
      font-size: 30px;
    }
  }
}
</style>
