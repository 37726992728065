<template>
  <div class="footer">
    <div class="main-info-block">
      <div>
        <h2 class="title">
          {{ $t("page.referralForm.footer.title") }}
        </h2>
        <p class="subtitle">{{ $t("page.referralForm.footer.subtitle") }}</p>
      </div>
      <ReferralForm class="referral-form" is-footer border-color="black" />
    </div>
    <div class="other-info-block">
      {{ $t("page.referralForm.footer.copyright") }}
    </div>
  </div>
</template>

<script>
import ReferralForm from "../form";

export default {
  name: "Footer",

  components: {
    ReferralForm,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat";
  color: #1a1a1b;

  .main-info-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1280px;
    padding: 45px 60px 108px;
    width: 100%;
    background-image: url("../../../../public/media/landing/bg/bg-6.png"),
      linear-gradient(74.8deg, #fff2fa 0%, #ddedff 100%, #ddedff 100%);
    background-repeat: no-repeat;
    background-position: 379px 340px, center top;
    background-size: 150px 150px, 100% 100%;

    .title {
      padding-top: 100px;
      margin-bottom: 40px;
    }

    .title,
    .subtitle {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      max-width: 560px;
    }

    .referral-form::v-deep {
      max-width: 490px;

      .form-control,
      .multiselect__tags,
      .multiselect__input {
        background-color: inherit;
      }

      .form-group__label {
        background-color: #eaf1fa;
      }
    }
  }

  .other-info-block {
    min-height: 72px;
    width: 100%;
    padding: 24px 60px 0;
    background-color: $white;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1170px) {
  .footer {
    .main-info-block {
      background-position: center 230px, center top;

      .title {
        max-width: 100%;
      }

      .subtitle {
        margin-bottom: 40px;
      }

      .referral-form::v-deep {
        .form-group__label {
          background-color: #fff2fa;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .footer {
    .main-info-block {
      background-position: 100% 160px, center top;
      background-size: 120px 120px, 100% 100%;
      padding: 45px 50px 108px;

      .title,
      .subtitle {
        padding-top: 0;
        font-size: 28px;
        line-height: 48px;
      }

      .subtitle {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 570px) {
  .footer {
    .main-info-block {
      background-position: 107% 160px, center top;
      padding: 80px 20px;

      .title,
      .subtitle {
        font-size: 28px;
        line-height: 48px;
      }
    }

    .other-info-block {
      padding: 16px 20px 0;
      justify-content: left;
    }
  }
}
</style>
