<template>
  <div class="table-info-block">
    <h6 class="title">
      {{ description.title }}
    </h6>

    <div class="main-info-block">
      <p class="description">
        {{ description.description }}
      </p>

      <ul class="list">
        <li v-for="(item, index) of description.needsList" :key="index" class="item">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableInfoBlock",

  props: {
    description: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.table-info-block {
  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    padding: 24px 0 32px 60px;
    border-bottom: 2px solid $white;
  }

  .main-info-block {
    display: flex;
    flex-direction: column;
    padding: 32px 64px 60px;

    .description {
      margin-bottom: 40px;
    }

    .list {
      display: block;
      margin-left: 20px;

      .item {
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 10px;
        list-style-type: none;
        list-style-image: url("../../../../../../public/media/landing/svg/vector.svg");
        padding-left: 10px;
      }

      :first-child {
        padding-left: 0;
        margin-left: -25px;
        font-size: 18px;
        list-style-image: none;
      }
    }
  }
}

@media (max-width: 1100px) {
  .table-info-block {
    .title {
      text-align: center;
      padding-left: 0;
    }

    .main-info-block {
      padding: 30px;
    }
  }
}

@media (max-width: 910px) {
  .table-info-block {
    .title {
      text-align: left;
      padding-left: 30px;
      border-top: 2px solid $white;
    }

    .main-info-block {
      padding: 30px;
    }
  }
}

@media (max-width: 510px) {
  .table-info-block {
    .title {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
</style>
