import gql from "graphql-tag";

export const regionsQuery = gql`
  query regions {
    regions {
      id
      name
      country {
        id
        name
      }
    }
  }
`;

export const requiredData = gql`
  query requiredData {
    referralOptions {
      options
    }
  }
`;

export const documentFormData = gql`
  query document($name: String) {
    document(name: $name) {
      name
      filename
      url
    }
  }
`;

