<template>
  <div class="services">
    <h2 class="title">{{ $t("page.referralForm.services.title") }}</h2>

    <div class="main-info-block">
      <ServiceInfoBlock
        v-for="(service, index) in services"
        :key="index"
        :service="service"
        class="service-info-block"
      />
    </div>
  </div>
</template>

<script>
import ServiceInfoBlock from "./components/ServiceInfoBlock";

export default {
  name: "Services",

  components: {
    ServiceInfoBlock,
  },

  computed: {
    services() {
      return [
        {
          title: this.$t("page.referralForm.replacement.title"),
          description: this.$t("page.referralForm.replacement.description"),
        },
        {
          title: this.$t("page.referralForm.monitoring.title"),
          description: this.$t("page.referralForm.monitoring.description"),
        },
        {
          title: this.$t("page.referralForm.service.title"),
          description: this.$t("page.referralForm.service.description"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  max-width: 1280px;
  width: 100%;
  padding: 50px 60px 100px;
  margin: 0 auto;
  font-family: "Montserrat";
  color: #1a1a1b;
  background-color: $white;

  .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 58px;
    margin-bottom: 80px;
  }

  .main-info-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .service-info-block:nth-child(2) {
      border-left: 1px solid #ffcad4;
      border-right: 1px solid #ffcad4;
      width: 415px;
      padding: 0 48px;
      margin: 0 10px;
    }
  }
}

@media (max-width: 1220px) {
  .services {
    padding: 50px 60px;

    .main-info-block {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .service-info-block {
        width: 100%;
      }

      .service-info-block:nth-child(2) {
        border-left: 0;
        border-right: 0;
        border-top: 1px solid #ffcad4;
        border-bottom: 1px solid #ffcad4;
        width: 100%;
        padding: 48px 0;
        margin: 48px 0;
      }
    }
  }
}

@media (max-width: 750px) {
  .services {
    padding: 40px 50px;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 58px;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 570px) {
  .services {
    padding: 40px 20px 20px;

    .title {
      max-width: 350px;
    }
  }
}
</style>
