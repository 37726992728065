<template>
  <div class="comparison-stations-table">
    <h6 class="title">{{ $t("page.referralForm.comparisonStations._") }}</h6>

    <div class="table">
      <div class="table-header">
        <div class="subtitle">{{ $t("page.referralForm.comparisonStations.autonomous") }}</div>
        <div class="subtitle">{{ $t("page.referralForm.comparisonStations.hybrid") }}</div>
      </div>

      <TableRow
        v-for="(description, index) of descriptionsList"
        :key="index"
        :description="description"
      />
    </div>
  </div>
</template>

<script>
import TableRow from "./TableRow";

export default {
  name: "ComparisonStationsTable",

  components: {
    TableRow,
  },

  computed: {
    imagePath() {
      return process.env.BASE_URL + "media/landing/images/image-1.png";
    },

    descriptionsList() {
      return [
        {
          label: this.$t("page.referralForm.comparisonStations.energyIndependence"),
          value: { autonomous: true, hybrid: true },
          icon: true,
        },
        {
          label: this.$t("page.referralForm.comparisonStations.protectionAgainstShutdowns"),
          value: { autonomous: false, hybrid: true },
          icon: true,
        },
        {
          label: this.$t("page.referralForm.comparisonStations.reductionCosts"),
          value: { autonomous: false, hybrid: true },
          icon: true,
        },
        {
          label: this.$t("page.referralForm.comparisonStations.saleOfEnergy"),
          sublabel: this.$t("page.referralForm.comparisonStations.saleOfEnergySublabel"),
          value: { autonomous: false, hybrid: true },
          icon: true,
        },
        {
          label: this.$t("page.referralForm.comparisonStations.withoutNetwork"),
          value: { autonomous: false, hybrid: true },
          icon: true,
        },
        {
          label: this.$t("page.referralForm.comparisonStations.initialCosts"),
          value: {
            autonomous: this.$t("page.referralForm.comparisonStations.high"),
            hybrid: this.$t("page.referralForm.comparisonStations.average"),
          },
          icon: false,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.comparison-stations-table {
  font-family: "Montserrat";
  font-style: normal;
  color: #1a1a1b;

  .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 58px;
    margin-bottom: 48px;
  }

  .table {
    overflow-x: auto;
    height: 470px;

    .table-header {
      width: 1150px;
      height: 80px;
      padding: 25px 25px 15px 520px;
      border-top: 1px solid #ffcad4;
      border-bottom: 1px solid #ffcad4;
      display: flex;

      .subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        width: 335px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 950px) {
  .comparison-stations-table {
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 58px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 750px) {
  .comparison-stations-table {
    .table {
      .table-header {
        width: 950px;
        padding: 25px 25px 15px 400px;

        .subtitle {
          width: 262px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .comparison-stations-table {
    .table {
      overflow-x: scroll;

      .table-header {
        width: 850px;
        padding: 25px 25px 15px 320px;

        .subtitle {
          width: 262px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .comparison-stations-table {
    .table {
      overflow-x: scroll;

      .table-header {
        width: 630px;
        padding: 25px 25px 15px 300px;

        .subtitle {
          width: 150px;
        }
      }
    }
  }
}
</style>
