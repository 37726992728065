<template>
  <div class="benefits">
    <div class="header-block">
      <h2 class="title">
        {{ $t("page.referralForm.benefits.title") }}
      </h2>

      <div class="description-block">
        <p class="description">
          {{ $t("page.referralForm.benefits.subtitleFirst") }}
        </p>
        <p class="description">
          {{ $t("page.referralForm.benefits.subtitleSecond") }}
        </p>
      </div>
    </div>

    <div class="main-info-block">
      <BenefitInfoBlock
        v-for="(benefit, index) in benefits"
        :key="index"
        :benefit="benefit"
        class="benefit-info-block"
      />
    </div>
  </div>
</template>

<script>
import BenefitInfoBlock from "./components/BenefitInfoBlock";

export default {
  name: "Benefits",

  components: {
    BenefitInfoBlock,
  },

  computed: {
    benefits() {
      return [
        {
          title: this.$t("page.referralForm.energyIndependence.title"),
          description: this.$t("page.referralForm.energyIndependence.description"),
          icon: process.env.BASE_URL + "media/landing/svg/benefit-1.svg",
        },
        {
          title: this.$t("page.referralForm.constancy.title"),
          description: this.$t("page.referralForm.constancy.description"),
          icon: process.env.BASE_URL + "media/landing/svg/benefit-2.svg",
        },
        {
          title: this.$t("page.referralForm.continuity.title"),
          description: this.$t("page.referralForm.continuity.description"),
          icon: process.env.BASE_URL + "media/landing/svg/benefit-3.svg",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.benefits {
  max-width: 1280px;
  width: 100%;
  padding: 100px 60px;
  margin: 0 auto;
  background-image: url("../../../../../public/media/landing/bg/bg-3.png"),
    linear-gradient(74.8deg, #fff2fa 0%, #ddedff 100%, #ddedff 100%);
  background-repeat: no-repeat;
  background-position: right bottom, center top;
  background-size: 333px 108px, 100% 100%;
  font-family: "Montserrat";
  font-style: normal;
  color: #1a1a1b;

  .header-block {
    display: flex;
    justify-content: left;
    min-height: 171px;
    margin-bottom: 60px;

    .title {
      font-weight: 700;
      font-size: 44px;
      line-height: 58px;
      max-width: 410px;
      margin-right: 80px;
    }

    .description-block {
      padding-top: 10px;

      .description {
        max-width: 562px;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
      }

      .description + .description {
        margin-top: 20px;
      }
    }
  }

  .main-info-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .benefit-info-block:nth-child(2) {
      margin: 60px 30px 30px;
    }
  }
}

@media (max-width: 1280px) {
  .benefits {
    .main-info-block {
      justify-content: center;

      .benefit-info-block:nth-child(2) {
        margin: 0 30px 30px;
      }
    }
  }
}

@media (max-width: 1120px) {
  .benefits {
    padding-bottom: 80px;

    .header-block {
      flex-direction: column;
      min-height: auto;
      margin-bottom: 60px;

      .title {
        margin-right: 0;
        margin-bottom: 32px;
        max-width: 100%;
      }

      .description-block {
        padding-top: 0;

        .description {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .benefits {
    .main-info-block {
      .benefit-info-block:nth-child(2) {
        margin: 0;
      }

      .benefit-info-block {
        margin: 40px 0;
      }
    }
  }
}

@media (max-width: 750px) {
  .benefits {
    padding-top: 80px;
    padding-bottom: 60px;

    .header-block {
      margin-bottom: 40px;

      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 58px;
      }

      .description-block {
        .description {
          font-size: 16px;
          line-height: 27px;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .benefits {
    padding: 80px 20px;

    .header-block {
      .title {
        max-width: 335px;
      }
    }

    .main-info-block {
      .benefit-info-block {
        margin: 40px 0;
      }
    }
  }
}
</style>
