<template>
  <div class="comparison-stations">
    <h6 class="title">{{ $t("page.referralForm.comparisonStations.title") }}</h6>

    <div class="table">
      <TableInfoBlock
        v-for="(description, index) of descriptionsList"
        :key="index"
        class="table-info-block"
        :description="description"
      />
    </div>
  </div>
</template>

<script>
import TableInfoBlock from "./TableInfoBlock";

export default {
  name: "ComparisonStations",

  components: {
    TableInfoBlock,
  },

  computed: {
    descriptionsList() {
      return [
        {
          title: this.$t("page.referralForm.comparisonStations.autonomousStation"),
          description: this.$t("page.referralForm.comparisonStations.autonomousStationDescription"),
          needsList: [
            this.$t("page.referralForm.comparisonStations.descriptionListTitle"),
            this.$t("page.referralForm.comparisonStations.privateHouse"),
            this.$t("page.referralForm.comparisonStations.apartment"),
          ],
        },
        {
          title: this.$t("page.referralForm.comparisonStations.hybridStation"),
          description: this.$t("page.referralForm.comparisonStations.autonomousStationDescription"),
          needsList: [
            this.$t("page.referralForm.comparisonStations.descriptionListTitle"),
            this.$t("page.referralForm.comparisonStations.countryHouse"),
            this.$t("page.referralForm.comparisonStations.apartment"),
            this.$t("page.referralForm.comparisonStations.socialInfrastructure"),
            this.$t("page.referralForm.comparisonStations.enterprises"),
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.comparison-stations {
  width: 100%;
  background: linear-gradient(74.8deg, #fff2fa 0%, #ddedff 100%, #ddedff 100%);
  font-family: "Montserrat";
  font-style: normal;
  color: #1a1a1b;

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    padding: 80px 20px 48px;
    border-bottom: 2px solid $white;
    margin-bottom: 0;
  }

  .table {
    display: flex;

    .table-info-block::v-deep {
      width: 50%;

      .title:first-child {
        border-right: 2px solid $white;
      }
    }
  }
}

@media (max-width: 1200px) {
  .comparison-stations {
    .title {
      padding: 40px 20px 24px;
    }
  }
}

@media (max-width: 910px) {
  .comparison-stations {
    .title {
      border-bottom: none;
      text-align: left;
      padding: 30px;
    }

    .table {
      flex-wrap: wrap;

      .table-info-block::v-deep {
        min-width: 395px;
        width: 100%;

        .title:first-child {
          border-right: none;
        }
      }
    }
  }
}

@media (max-width: 510px) {
  .comparison-stations {
    .title {
      font-size: 22px;
      line-height: 26px;
    }

    .table {
      .table-info-block::v-deep {
        min-width: auto;
      }
    }
  }
}
</style>
