<template>
  <div class="table-row">
    <div class="table-row-label">
      <div class="first-row">
        {{ description.label }}
      </div>
      <div v-if="description.sublabel" class="second-row">
        {{ description.sublabel }}
      </div>
    </div>

    <div class="table-row-values">
      <div v-for="(value, index) of Object.values(description.value)" :key="index">
        <div v-if="description.icon" class="image">
          <img :src="getImagePath(value)" alt />
        </div>
        <div v-else class="value">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableRow",

  props: {
    description: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    getImagePath(value) {
      let path;

      value ? (path = "media/landing/svg/check.svg") : (path = "media/landing/svg/cancel.svg");

      return process.env.BASE_URL + path;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  width: 1150px;
  border-bottom: 1px solid #ffcad4;
  height: 64px;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  color: #1a1a1b;

  &-label {
    width: 520px;
    display: flex;
    flex-direction: column;

    .first-row {
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      width: 520px;
    }

    .second-row {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
    }
  }

  &-values {
    display: flex;

    .image {
      width: 300px;
      display: flex;
      justify-content: center;
    }

    .value {
      width: 300px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
    }
  }
}

@media (max-width: 750px) {
  .table-row {
    width: 950px;

    &-label {
      width: 400px;
    }

    .image {
      width: 260px;
    }

    .value {
      width: 260px;
    }
  }
}

@media (max-width: 650px) {
  .table-row {
    width: 850px;

    &-label {
      width: 320px;
    }
  }
}

@media (max-width: 450px) {
  .table-row {
    width: 630px;

    &-label {
      width: 305px;
    }

    .image {
      width: 144px;
    }

    .value {
      width: 144px;
    }
  }
}
</style>
