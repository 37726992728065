<template>
  <div class="about-info-block">
    <img class="image" :src="constituent.icon" alt />
    <div>
      <h6 class="title">{{ constituent.title }}</h6>
      <p class="description">{{ constituent.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutInfoBlock",

  props: {
    constituent: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.about-info-block {
  width: 606px;
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  color: #1a1a1b;

  .image {
    height: 60px;
    width: 60px;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 12px;
    padding-top: 20px;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    max-width: 522px;
  }
}

@media (max-width: 750px) {
  .about-info-block {
    width: 100%;

    .image {
      margin-right: 24px;
    }

    .title {
      padding-top: 0;
    }
  }
}
</style>
