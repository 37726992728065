<template>
  <div class="benefit-info-block">
    <img class="image" :src="benefit.icon" alt />
    <h6 class="title">{{ benefit.title }}</h6>
    <p class="description">{{ benefit.description }}</p>
  </div>
</template>

<script>
export default {
  name: "BenefitInfoBlock",

  props: {
    benefit: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit-info-block {
  height: 397px;
  width: 366px;
  background-color: $white;
  padding: 48px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  text-align: center;
  color: #1a1a1b;

  .image {
    margin-bottom: 12px;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 20px;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 910px) {
  .benefit-info-block {
    padding: 28px 24px;
    height: auto;
    width: 100%;
  }
}

//@media (max-width: 400px) {
//  .benefit-info-block {
//    width: 100%;
//  }
//}
</style>
